import {BrowserRouter as Router, Link, Route, Switch} from "react-router-dom";
import React from "react";
import {OverviewWidget} from "./widget-routes/OverviewWidget";
import {EditWidget} from "./widget-routes/EditWidget";
import {SettingsWidget} from "./widget-routes/SettingsWidget";
import {StatisticsWidget} from "./widget-routes/StatisticsWidget";

export function Routes() {
    return <Router>
        <div>
            <Switch>
                <Route exact path="/">
                    <Home/>
                </Route>
                <Route path="/:brand/overview/:externalListingId">
                    <OverviewWidget/>
                </Route>
                <Route path="/:brand/edit/:externalListingId">
                    <EditWidget/>
                </Route>
                <Route path="/:brand/statistics/:externalListingId">
                    <StatisticsWidget/>
                </Route>
                <Route path="/:brand/settings/">
                    <SettingsWidget/>
                </Route>

            </Switch>
        </div>
    </Router>
}



function Home() {
    return (
        <div>
            <h2>Home</h2>
            <ul>
                <li>
                    <Link to="/">Home</Link>
                </li>
                <li>
                    <Link to="/user-details">User Details</Link>
                </li>
                <li>
                    <Link to="/listing-overview">Listing Overview</Link>
                </li>
                <li>
                    <Link to="/listing-edit">Listing Edit</Link>
                </li>
            </ul>
        </div>
    );
}