import React, {useState, useEffect, useContext, useCallback} from "react";
import {useParams} from "react-router-dom";
import {ListingAdWithCampaignStatus} from "../components/ListingAdWithCampaignStatus";
import {ListingAdCampaignWizard} from "../components/ListingAdCampaignWizard";
import {Spinner} from "../components/spinner/Spinner";
import * as apiService from "../service/ApiService";
import {MissingUserError} from "../components/error/MissingUserError";
import {ObjectContext} from "../context/ObjectContext";
import {UserContext} from "../context/UserContext";
import {WaitingForLisiting} from "../components/waiting-for-listing/WaitingForLisiting";
import {logger} from "../service/LoggerService";

export function OverviewWidget() {
    logger.log("OverviewWidget()...");
    let { externalListingId } = useParams();
    const { state, setState } = useContext(ObjectContext);
    const { userData } = useContext(UserContext);
    const [loading, setLoading] = useState(true);


    const fetchAsync = useCallback(async () => {
        logger.log('Fetching brief...')
        const data = await apiService.getListingBrief(externalListingId);
        logger.log('DONE Fetching brief...')
        logger.log(data)
        if (data.error?.status === 404) {
            setLoading(false)
            return
        }

        const campaignSettingsForListingId = await apiService.campaignSettingsForExtendedListingId(data._id)

        const objectCopy = {...state};
        objectCopy.extendedListing = data
        objectCopy.campaignSettingsForListingId = campaignSettingsForListingId?.campaignConfigForExtendedListing
        setState(objectCopy);
        setLoading(false)


    }, [state, externalListingId, setState])

    useEffect(() => {
        fetchAsync()
    }, []);

    if (loading) {
        return <div className="center">
            <Spinner/>
        </div>
    }

    if (userData.userInfo === null && userData.tokenInfo !== null) {
        return <MissingUserError></MissingUserError>
    }

    if (state.extendedListing) {
        if (state.extendedListing.campaignState === 'onHold' || state.extendedListing.campaignState === 'pending' ) {
            return <ListingAdCampaignWizard></ListingAdCampaignWizard>
        } else if (state.extendedListing.campaignState === 'created' || state.extendedListing.campaignState === 'stopped') {
            return <ListingAdWithCampaignStatus></ListingAdWithCampaignStatus>
        } else {
            return <Spinner/>
        }
    } else {
        return <WaitingForLisiting></WaitingForLisiting>
    }
}

