import React, {useState, useContext, useEffect, useCallback} from "react";
import '../App.css';
import {ConfirmedStep} from "./campaign-creation-steps/ConfirmedStep";
import {ListingNotAdvertisedStep} from "./campaign-creation-steps/ListingNotAdvertizedStep";
import {PackageSelectionStep} from "./campaign-creation-steps/PackageSelectionStep";
import {BudgetSelectionStep} from "./campaign-creation-steps/BudgetSelectionStep";
import * as apiService from "../service/ApiService";
import {ObjectContext} from "../context/ObjectContext";
import {Spinner} from "reactstrap";
import {logger} from "../service/LoggerService";

export function ListingAdCampaignWizard() {

    const choosePackageStep = 1
    const chooseBudgetStep = 2
    const confirmationStep = 3
    const { state, setState } = useContext(ObjectContext);
    const [createCampaignStep, setCreateCampaignStep ]= useState(state.extendedListing.campaignState === 'pending' ? confirmationStep : 0);


    const fetchAsync = useCallback(async () => {
        const listingAdId = state.extendedListing._id
        const campaignOptions = await apiService.getCampaignOptions(listingAdId);
        const objectCopy = {...state, campaignOptions};
        // objectCopy.listingAdData = data
        setState(objectCopy);

    }, [setState, state, createCampaignStep])

    useEffect(() => {
        fetchAsync();
    }, []);

    async function nextStepButtonHandler() {
        logger.log("createCampaignStep: " + createCampaignStep)
        setCreateCampaignStep(createCampaignStep + 1)
    }
    async function prevStepButtonHandler() {
        logger.log("createCampaignStep: " + createCampaignStep)
        setCreateCampaignStep(createCampaignStep - 1)
    }

    let component = <ListingNotAdvertisedStep nextStepButtonHandler={nextStepButtonHandler} />

    if (createCampaignStep === choosePackageStep) {
        component = <PackageSelectionStep nextStepButtonHandler={nextStepButtonHandler} prevStepButtonHandler={prevStepButtonHandler}/>
    }

    if (createCampaignStep === chooseBudgetStep) {
        component = <BudgetSelectionStep nextStepButtonHandler={nextStepButtonHandler} prevStepButtonHandler={prevStepButtonHandler}/>
    }

    if (createCampaignStep === confirmationStep) {
        component = <ConfirmedStep />
    }

    return (
        <div className='container-fluid bbBG'>
            {/*
            <pre>
            { 'userdata: ' + JSON.stringify(userData.campaignSettings, null, 3)}
            </pre>*/}
            <div className={'row'}>
                <div className={'col bbWidget'}>
                    {component}

                    {/*
                    <div className={'bbWidget__copy'}>
                        <span className={'bbWidget__copy--bb'}>BrightBee</span>
                        <span className={'bbWidget__copy--bbCopyright'}>© 2021 BrightBee Marketing AB</span>
                    </div> */}
                </div>
            </div>
        </div>)
}
