import React, {useCallback, useContext, useEffect, useState} from "react";
import * as apiService from "../../service/ApiService";
import {getApiUserToken} from "../../service/ApiService";
import {ObjectContext} from "../../context/ObjectContext";
import {buttonClassName} from "../../utils/utils";
import MobileAdPreview from "../../imported-kw-components/MobileAdPreview";
import AdSettings from "../../imported-kw-components/AdSettings/ad-settings";
import {useParams} from "react-router-dom";
import {ConfirmedStep} from "../campaign-creation-steps/ConfirmedStep";
import {Spinner} from "../spinner/Spinner";
import {logger} from "../../service/LoggerService";
import { X } from 'react-bootstrap-icons';
import {Input} from "reactstrap";


async function reloadListingAd(state, setState) {
    let objectCopy = {...state};
    const listingAd = await apiService.getListingAd(state.listingAd._id);
    objectCopy.listingAd = listingAd
    setState(objectCopy);
}


export function EditingWidgetForListingAd() {
    const { state, setState} = useContext(ObjectContext);
    let { externalListingId } = useParams();

    const [loading, setLoading] = useState(false);
    const [listingImage, setListingImage] = useState("https://realreach.s3.eu-north-1.amazonaws.com/" + state.listingAd.listingAdImages[0] );
    const [listingImageData, setListingImageData] = useState(null);
    const extraRegions = state.extendedListing.extraRegions ? state.extendedListing.extraRegions : [];
    const [additionalRegions, setAdditionalRegions] = useState([...extraRegions]);

    const [description, setDescription] = useState('');
    const [headline, setHeadline] = useState('');
    const [message, setMessage] = useState('');
    const [website, setWebsite] = useState('');
    const [businessName, setBusinessName] = useState('');
    const [finishedWithEdititng, setFinishedWithEdititng] = useState(false);

    async function onSelectFiles(e) {
        const file = e.target.files[0];
        if (!file) {
            setListingImage(null)
        } else if (file.name.toLowerCase().match(/\.(jpg|jpeg|png|gif)$/)) {
            setListingImage(URL.createObjectURL(e.target.files[0]))
            setListingImageData({ mediaType: "image", listingFile: file })
        }
    };

    function setupControlsFromCurrentExtendedListingState() {
        setDescription(state.listingAd.description)
        setHeadline(state.listingAd.headline)
        setMessage(state.listingAd.message)
        setWebsite(state.listingAd.website)
        setBusinessName(state.listingAd.businessName)
        setListingImage("https://realreach.s3.eu-north-1.amazonaws.com/" + state.listingAd.listingAdImages[0])
    }

    const fetchAsync = useCallback(async () => {
        setDescription(state.listingAd.description)
        setHeadline(state.listingAd.headline)
        setMessage(state.listingAd.message)
        setWebsite(state.listingAd.website)
        setBusinessName(state.listingAd.businessName)
    }, [state])

    useEffect(() => {
        fetchAsync();
    }, []);

    function isDirty() {
        return description !== state.listingAd.description
            || headline !== state.listingAd.headline
            || message !== state.listingAd.message
            || businessName !== state.listingAd.businessName
            || website !== state.listingAd.website
            || listingImageData !== null
    }

    function closeView() {
        logger.log('posting close_brightbee_edit_widget...')
        window.top.postMessage('close_brightbee_edit_widget','*')
    }

    async function save() {
        logger.log("save")
        logger.log("additionalRegions")
        logger.log(additionalRegions)
        setFinishedWithEdititng(true)

        if (isDirty()) {
            setLoading(true)
            await apiService.saveListingAd(
                state.listingAd._id,
                {
                    headline, description, message, website, businessName
                });

            if (listingImageData) {
                let formdataWithImage = new FormData()
                formdataWithImage.append('listingAdImages', listingImageData.listingFile);
                await apiService.saveListingAdImage(
                    state.listingAd._id,
                    formdataWithImage);
            }
            await reloadListingAd(state, setState);
            setLoading(false)
        }

        await apiService.saveExtendedListing(state.extendedListing._id, { extraRegions: additionalRegions });
        closeView();
    }

    function cancel() {
        logger.log("cancel")
        if (isDirty()) {
            setupControlsFromCurrentExtendedListingState();
        } else {
            let token = getApiUserToken()
            let urlWithToken = `/quedro/overview/${externalListingId}?token=${token}`;
            window.location.href = urlWithToken
        }
        closeView();
    }


    if (finishedWithEdititng) {
        return <ConfirmedStep></ConfirmedStep>
    }

    let adInfoRows =
    <div>
        <div className='row'>
                <div className="form-group">
                    <label className="label">
                        Meddelande </label>
                    <textarea resize="none" className="form-control" type='text' value={message} size="40" onChange={e => setMessage(e.target.value)}/>
                </div>
        </div>
        <div className='row'>
                <div className="form-group">
                    <label className="label">
                        Webbsajt </label>
                    <input className="form-control" type='text' value={website} size="40" onChange={e => setWebsite(e.target.value)}/>
                </div>
        </div>

        <div className='row'>
                <div className="form-group">
                <label className="label">
                    Rubrik </label>
                <input className="form-control" type='text' value={headline} size="40" onChange={e => setHeadline(e.target.value)}/>
            </div>
        </div>

        <div className='row'>

                <div className="form-group">
                    <label className="label">
                        Beskrivning</label>
                    <input className="form-control" type='text' value={description} size="40" onChange={e => setDescription(e.target.value)}/>
                </div>
        </div>

        <div className='row'>
                <div  className="col mt-3">
                    <button
                        className="btnType2"
                        type="button"
                        onClick={() => document.getElementById("selectFile").click()}
                    >Byt bild</button>
                </div>

                <Input
                    type="file"
                    id="selectFile"
                    style={{ display: 'none' }}
                    onChange={onSelectFiles}
                />
        </div>

    </div>


    let mediaType;


    async function removeAd() {
        await apiService.forceExtendedListingToOnHold(state.extendedListing._id)
        closeView()
    }

    const cancelSaveButtons = <div className='bbWidget__edit--buttons'>
        <button className='bbWidget__button bbWidget__button--cancel' onClick={() => cancel()}>Avbryt</button>
        <a href="#" onClick={() => removeAd()} className="bbText--link">Ta bort annons</a>
        <button className='bbWidget__button  bbWidget__button--save' onClick={() => save()}>Spara ändringar</button>
        <X onClick={() => cancel()}/>
    </div>
    return <div>
        <span className="bbText bbText--editTitle">Redigera annons</span>
        <div className='container-fluid'>
            <div className='row'>
                <div className='col-7'>
                    {adInfoRows}
                </div>
                <div className='col-5'>
                    <MobileAdPreview
                        message={message}
                        businessName={businessName}
                        headline={headline}
                        website ={website}
                        description={description}
                        soldShow={false}
                        underOfferShow={false}
                        forSaleShow={false}
                        profileImageShow={false}
                        mediaType={mediaType}
                        stuff={listingImage}
                    />
                </div>
            </div>
            <div className="row">
                <AdSettings
                    additionalRegions={additionalRegions}
                    setAdditionalRegions={setAdditionalRegions}
                    />
            </div>
            <div className='row'>
                {loading ?
                    <div style={{marginTop: '80px'}} className="center"><Spinner></Spinner></div>
                    : ''
                }
                {cancelSaveButtons}
            </div>
        </div>
    </div>
}
