import React, {useContext, useEffect, useState} from "react";

import * as apiService from "../service/ApiService";
import {useParams} from "react-router-dom";
import { Progress } from 'reactstrap';
import {ObjectContext} from "../context/ObjectContext";

function getDiffDays(date2, date1) {
    const diffTime = Math.abs(date2.getTime() - date1.getTime())
    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
    return diffDays;
}

export function ListingAdWithCampaignStatus() {

    let { externalListingId } = useParams();
    const { state, setState } = useContext(ObjectContext);
    const [loading, setLoading] = useState(true);


    const [chartFill, setChartFill] = useState(0);
    const [campaignInProgress, setShowProgressWidgets] = useState(true);


    useEffect(() => {
        async function doStuff() {
            const data = await apiService.getListing(externalListingId);
            const objectCopy = {...state};
            objectCopy.listingAdData = data
            setState(objectCopy);
            setLoading(false)
        }
        doStuff();
    }, []);


    useEffect(() => {
      setTimeout(() => {
        const diffDays = getDiffDays(new Date(state.extendedListing.createdAt), new Date())
        const campaignDays = state.campaignSettingsForListingId.campaignDays
          console.log('campaignDays')
          console.log(campaignDays)
        let fillPercent = diffDays / campaignDays * 100
        if (fillPercent > 100){
            fillPercent = 100
        }

        setChartFill(fillPercent)

        if (fillPercent >= 100) {
            setTimeout(() => {
                setShowProgressWidgets (false)
                setChartFill(100)
            }, 2000)
        }
      }, 1000)

    }, []);

    let chFill = chartFill

    let statsElems = null

    if (state.listingAdData && state.listingAdData.stats) {

        statsElems = <div className="row">
            <div className="col-12 bbText__stats">
                <span className="bbText__stats--stat">
                    <span className="bbText__stats--statNumber">{state.listingAdData.stats.reach}</span>
                    <span className="bbText__stats--statText">Unika personer nådda</span>
                </span>
                <span className="bbText__stats--stat">
                    <span className="bbText__stats--statNumber">{state.listingAdData.stats.impressions}</span>
                    <span className="bbText__stats--statText">Totala visningar</span>
                </span>
                <span className="bbText__stats--stat">
                    <span className="bbText__stats--statNumber">{state.listingAdData.stats.clicks}</span>
                    <span className="bbText__stats--statText">Klick</span>
                </span>
            </div>
        </div>
    }
    return (
        <div className='container-fluid bbBG'>
            <div className="row">
                <div className="bbWidget container-fluid">
                    <div className="col-12 bbWidget__inner bbWidget__inner--results">
                        <div className="bbWidget__results bbText">
                            <span className="bbText__result bbText__result--name">{state.extendedListing.address.street}</span>
                            <span className="bbText__result bbText__result--progress">{ campaignInProgress ? 'resultat på sociala medier' : 'är färdigannonserad' }</span>
                            <Progress className="bbWidget__progress" color="brightBee" value={chFill} />
                            {statsElems}
                            <br/>
                            <span className="bbText__result bbText__result--progress">{ state.extendedListing.campaignState === 'stopped' ? 'Kampanjen har avslutats' :'' }</span>
                        </div>
                    </div>
                    {/*
                    <div className={'bbWidget__copy'}>
                        <span className={'bbWidget__copy--bb'}>BrightBee</span>
                        <span className={'bbWidget__copy--bbCopyright'}>© 2021 BrightBee Marketing AB</span>
                    </div> */}
                </div>
            </div>
        </div>
    );
}
