import {getBaseURL, getQueryVariable} from "../utils/utils";
import {logger} from "../service/LoggerService";

export const baseUrl = getBaseURL()

export function getAndStoreApiUserTokenFromUrl() {
    logger.log("getAndStoreApiUserTokenFromUrl")
    let token = getQueryVariable('token');
    logger.log('token from query variable: ' + token)

    const currentToken = localStorage.getItem('token');
    logger.log('currentToken: ' + currentToken)

    if (token !== currentToken) {
        logger.log('New token, so setting local storage')
        localStorage.setItem('token', token)
    }
    return token;
}

export function getApiUserToken() {
    logger.log('getApiUserToken')
    const token = localStorage.getItem('token');
    logger.log(`token: ${token}`)
    return token
}

function getHeadersWithToken() {
    return {
        'Accept': 'application/json',
        'Authorization': 'Bearer ' + getApiUserToken(),
        'Content-Type': 'application/json'
    };
}

function getHeadersWithTokenForFileUpload() {
    return {
        'Authorization': 'Bearer ' + getApiUserToken()
    };
}

export function saveExtendedListing(extendedListingId, fields) {
    let url = baseUrl + '/widgets-support/extendedListingUpdate/' + extendedListingId

    return fetch(url, {
        method: 'POST',
        headers: getHeadersWithToken(),
        body: JSON.stringify(fields)
    }).then(response => response.json())
}

export async function getCampaignOptions(listingAdId) {
    let url = baseUrl + `/widgets-support/campaign/${listingAdId}/options`;
    return fetch(url, {
        method: 'GET',
        headers: getHeadersWithToken(),
    }).then(response => response.json())
}

export async function updateExtendedListingWithBudgetCampaignConfig(extendedListingId, selectedPackage, selectedBudgetType) {
    let url = baseUrl + '/widgets-support/updateExtendedListingForCampaign';
    const campaignConfig = {
        extendedListingId,
        packageType: selectedPackage,
        budgetType: selectedBudgetType
    };
    return fetch(url, {
        method: 'POST',
        headers: getHeadersWithToken(),
        body: JSON.stringify(campaignConfig)
    }).then(response => response.json())
}

export async function me() {
    let url = baseUrl + '/widgets-support/me';
    return fetch(url, {
        method: 'GET',
        headers: getHeadersWithToken()
    }).then(response => response.json());
}

export async function campaignSettingsForExtendedListingId(extendedListingId) {
    let url = baseUrl + '/widgets-support/campaignSettingsForExtendedListing?extendedListingId=' +  extendedListingId;
    return fetch(url, {
        method: 'GET',
        headers: getHeadersWithToken()
    }).then(response => response.json());
}

export async function getListing(externalListingId) {
    let url = baseUrl + '/widgets-support/extendedListingAndCampaignInfo?extListingId=' + externalListingId;
    return fetch(url, {
        method: 'GET',
        headers: getHeadersWithToken()
    }).then(response => response.json());
}

export async function getListingBrief(externalListingId) {
    let url = baseUrl + '/widgets-support/extendedListingByExtListingId?extListingId=' + externalListingId;
    return fetch(url, {
        method: 'GET',
        headers: getHeadersWithToken()
    }).then(response => response.json());
}

// TODO: remove this, and in backend
// export async function getCampaignInfoPreview(externalListing, token) {
//     let url = baseUrl + '/widgets-support/campaignPreview'
//     return fetch(url, {
//         method: 'POST',
//         headers: getHeadersWithToken(),
//         body: JSON.stringify(externalListing)
//     }).then(response => response.json());
// }

export async function getQuedroListingData(externalListingId) {
    let url = baseUrl + '/widgets-support/quedroDataByExtListingId?extListingId=' + externalListingId;
    return fetch(url,
        {
            method: 'GET',
            headers: getHeadersWithToken()
        }).then(response => response.json());
}

export async function getListingAd(id) {
    let url = baseUrl + '/widgets-support/listingAd/' + id;
    return fetch(url, {
        method: 'GET',
        headers: getHeadersWithToken()
    }).then(response => response.json());
}

export function saveListingAd(id, fields) {
    let url = baseUrl + '/widgets-support/listingAd/' + id
    return fetch(url, {
        method: 'POST',
        headers: getHeadersWithToken(),
        body: JSON.stringify(fields)
    }).then(response => response.json())
}

export function saveListingAdImage(id, formData) {
    let url = baseUrl + '/widgets-support/listingAd/' + id
    return fetch(url, {
        method: 'POST',
        headers: getHeadersWithTokenForFileUpload(),
        body: formData
    }).then(response => console.log("response: " + response))
}


export function forceExtendedListingToOnHold(id) {
    let url = baseUrl + '/widgets-support/forceExtendedListingToOnHold/' + id
    return fetch(url, {
        method: 'GET',
        headers: getHeadersWithToken(),
    }).then(response => response.json())
}