import React, {useContext, useState, useEffect, useCallback} from "react";
import {ObjectContext} from "../../context/ObjectContext";
import * as apiService from "../../service/ApiService";
import {logger} from "../../service/LoggerService";
import {ChevronLeft, ChevronRight} from 'react-bootstrap-icons';

export function BudgetSelectionStep(props) {

    const { state, setState } = useContext(ObjectContext);
    logger.log("BudgetSelectionStep, state: ", state)
    const [budgetType, setBudgetType] = useState(state.extendedListing.budgetType || 'standard');

    function setSelectedBudget(budgetT) {
        setBudgetType(budgetT)
        const newState = {...state, selectedBudget: budgetT};
        setState(newState)
    }


    const fetchAsync = useCallback(async () => {
        const listingAdId = state.extendedListing._id
        const campaignOptions = await apiService.getCampaignOptions(listingAdId)
        const objectCopy = {...state, campaignOptions}
        setState(objectCopy)

    }, [setState, state])


    useEffect( () => {
        fetchAsync()
    }, []);

    function createBudgetSelector(region, campaignOption) {
        return <div className={budgetType === campaignOption.budgetType ? "bbWidget__package bbWidget__package--budget bbWidget__package--selected" : "bbWidget__package bbWidget__package--budget"}  onClick={() => {
            setSelectedBudget(campaignOption.budgetType);
        }}>
                <span className="bbWidget__budgetText bbWidget__budgetText--name">{campaignOption.name}</span>
                <span className="bbWidget__budgetText bbWidget__budgetText--price">{campaignOption.budgetAmount} KR</span>
                {/*<span className="bbWidget__budgetText bbWidget__budgetText--reach">{campaignOption.reach.data.users}<span>Visningar</span></span>*/}
                {/* Område: {region[0].name}<br/> */}
                
        </div>;
    }

    return <div className='container-fluid bbWidget__inner bbWidget__inner--budgetSelect'>
        <span className="bbText--smallTitle">Välj budget:</span>
        <div className="row">
                {createBudgetSelector(state.campaignOptions.campaignCreationOptions.region, state.campaignOptions.campaignCreationOptions.options[0])}
                {createBudgetSelector(state.campaignOptions.campaignCreationOptions.region, state.campaignOptions.campaignCreationOptions.options[1])}
                {createBudgetSelector(state.campaignOptions.campaignCreationOptions.region, state.campaignOptions.campaignCreationOptions.options[2])}
        </div>
        <div className="bbWidget__inner--nav">
                <button type="button" className={"bbWidget__button bbWidget__button--next"}
                        onClick={async () => {
                            setSelectedBudget(budgetType)
                            const selectedPackage = state.selectedPackage;
                            const selectedBudget = budgetType
                            let extendedListingId = state.extendedListing._id
                            await apiService.updateExtendedListingWithBudgetCampaignConfig(extendedListingId, selectedPackage, selectedBudget);
                            logger.log("Uppdaterade ExtendedListing med budget & paket, " + state.extendedListing._id);
                            props.nextStepButtonHandler()
                        }}>
                    {state.extendedListing.campaignState === 'onHold' ? 'Publicera' : '' }
                    {state.extendedListing.campaignState === 'pending' ? 'Uppdatera' : '' }
                    {' annons'}
                    <ChevronRight/>
                </button>
                <button type="button" className={'bbWidget__button bbWidget__button--prev'}
                            onClick={() => {
                                props.prevStepButtonHandler()
                            }}> <ChevronLeft/>{' Gå Tillbaka'}
                    </button>
        </div>
    </div>;
}
