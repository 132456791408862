import React from "react";
import { Card, CardImg, CardImgOverlay, Row, Col, Button } from "reactstrap";

import "./video-react.css";
import "./MobileAdPreview.css";
import { Player } from "video-react";
import { trimLink } from "../helpers/Utils";
import UserAvatar from "../components/UserAvatar";
import ReadOnlyInput from "../components/ReadOnlyInput";
import { config } from '../../config';

const showStyle = {
  position: "absolute",
  left: 5,
  top: 5,
};
// TODO: Chainsaw styled to fit quedro preview, will be replaced with fb preview call
const MobileAdPreview = ({
  stuff,
  message,
  headline,
  website,
  soldShow,
  instagram,
  mediaType,
  description,
  forSaleShow,
  underOfferShow,
  profileImageShow,
  businessName,
}) => {

  const isAgentType = localStorage.getItem('campaignType') === 'agentAd';
  return (
    <Card className="mobilePreviewCard">
      <CardImg
        top
        src={`${config.BASE_URL}/assets/images/group.png`}
        alt="Card image cap"
        className="previewAdImage"
      />
      <CardImgOverlay className="cardImageOverlayCustomiztion">
        <div className="container">
          <Row className={`${instagram && "mb-2"} header align-items-center`}>
            {/*<Col>*/}
              {/*No image at the moment, we do not have one, so commenting out.*/}
              {/*<img*/}
              {/*  style={{ borderRadius: '100%' }}*/}
              {/*  src={`${config.BASE_URL}/assets/images/bbLogo.png`}*/}
              {/*  alt="head-logo"*/}
              {/*/>*/}
            {/*</Col>*/}
            <Col xs="6" style={{paddingLeft:0, margin:0}}>
              <div className="">
                <p className="title">{businessName}</p>
                <span className="subTitle">Sponsrad</span>
              </div>
            </Col>
            <Col xs="5">
              <p className={`${instagram ? 'previewAdPersonDot-inst' : 'previewAdPersonDot'} mr-2 mt-2`}></p>
            </Col>
          </Row>
          {!instagram && (
            <Row className="mt-1">
              <p style={{ overflowWrap: "anywhere" }}>
                {message !== "" ? message : "Here's message text"}
              </p>
            </Row>
          )}
          <div className="stuffDiv position-relative">
            {mediaType === "video" ? (
              <Player playsInline src={stuff} fluid={false} autoPlay />
            ) : (
                <CardImg
                  src={
                    stuff
                      ? stuff
                      : `${config.BASE_URL}/assets/images/dummy.jpg`
                  }
                  className="previewAdDetailImage"
                />
              )}
            {forSaleShow && (
              <div style={showStyle}>
                <img
                  style={{ width: 50, opacity: 0.9 }}
                  src={`${config.BASE_URL}/assets/images/forSale.png`}
                  alt="helpbuy"
                />
              </div>
            )}
            {soldShow && (
              <div style={showStyle}>
                <img
                  style={{ width: 50, opacity: 0.9 }}
                  src={`${config.BASE_URL}/assets/images/soldKW.png`}
                  alt="sold"
                />
              </div>
            )}
            {underOfferShow && (
              <div style={showStyle}>
                <img
                  style={{ width: 50, opacity: 0.9 }}
                  src={`${config.BASE_URL}/assets/images/underOffer.png`}
                  alt="sold"
                />
              </div>
            )}
            {profileImageShow && (
              <div className="profileImageShow">
                <UserAvatar
                  width="100%"
                  height="100%"
                  editable={false}
                  showNameOnly={true}
                />
              </div>
            )}
          </div>
          <Row className={instagram ? "footer-new" : "footer"}>
            {instagram && (
              <>
                <Col xs="12" className="p-0">
                  <CardImg
                    src={`${config.BASE_URL}/assets/images/insta-likes.png`}
                    className="previewAdLikeComments-inst"
                  />
                </Col>
                <Col xs="12" className="pt-2 pl-1">
                  <p>
                    <strong>{headline !== "" ? headline : "Headline"}</strong>
                  </p>
                  <p>
                    {message !== "" ? message : "Here's message text"}
                  </p>
                </Col>
              </>
            )}
            {!instagram && (
              <>
                {/*<Col xs="7" className="pt-2 pl-1">*/}
                {/*  <ReadOnlyInput size={10} value={website !== "" ? trimLink(website) : "example.com"} />*/}
                {/*</Col>*/}
                <Col xs="7" className="text-left" style={{padding: 0}}>

                    <span style={{textTransform: 'uppercase',whiteSpace: 'nowrap', textOverflow:'elipsis', overflow: 'hidden'}}>{website !== "" ? trimLink(website) : "example.com"}</span>
                    <div>{headline !== "" ? headline : "Headline"}</div>
                    <div><strong>{description !== "" ? description : "This is description text"}</strong></div>
                </Col>
                <Col xs="5" className="text-center">
                  <Button className="adPreviewCustomButton" outline>
                    LÄS MER
                    {/*{isAgentType ? 'Book now' : 'Learn more'}*/}
                  </Button>
                </Col>

                <Col xs="12" className="p-0 mt-2 pl-1">
                  <br/>
                  <br/>
                  <br/>
                  <br/>
                  <br/>
                  <br/>
                  <br/>
                  {/*<CardImg*/}
                  {/*  src={`${config.BASE_URL}/assets/images/like-comments.png`}*/}
                  {/*  className="previewAdLikeComments"*/}
                  {/*/>*/}
                </Col>
              </>
            )}
          </Row>
        </div>
      </CardImgOverlay>
    </Card>
  )
};

export default MobileAdPreview;
